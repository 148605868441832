import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MainFooterComponent } from '@components/main-footer/main-footer.component';
import { MainHeaderComponent } from '@components/main-header/main-header.component';

@Component({
  selector: 'app-product-list',
  standalone: true,
  imports: [
    MainHeaderComponent,
    MainFooterComponent,
    RouterModule
  ],
  templateUrl: './product-list.component.html',
  styleUrl: './product-list.component.scss'
})
export class ProductListPage {
  public products: any[] = [
    {
      title: 'Aisladores',
      products: [
        {
          title: 'Vidrio templado tipo suspensión',
          text: 'Soporte no conductor compuesto por un esbozo de vidrio aislante y herrajes para el acoplamiento con otras unidades en serie. Tiene un herraje de sujeción para el soporte no rígido de los conductores eléctricos.',
          image: 'img1175.jpg'
        },
        {
          title: 'Porcelana tipo carrete',
          text: 'Soporte aislante de forma cilíndrica para un conductor eléctrico, con una o varias ranuras circunferenciales externas y perforado axialmente para su montaje.',
          image: 'img1181.jpg'
        },
        {
          title: 'Porcelana tipo retenida',
          text: 'Aislador de forma cilíndrica con dos ranuras transversales.',
          image: 'img1185.jpg'
        },
        {
          title: 'Porcelana tipo poste línea',
          text: 'Aislador dieléctrico ensamblado a una base metálica. Se coloca rígidamente a una estructura o cruceta por medio de un perno.',
          image: 'img1191.jpg'
        },
        {
          title: 'Porcelana tipo columna',
          text: 'Aislador compuesto de porcelana clase C-110. Su forma es cilíndrica, con faldones simétricos o alternados, proporciona el aislamiento necesario en conductores y equipos eléctricos, y es adecuado para los diferentes niveles de contaminación.',
          image: 'img1197.jpg'
        },
        {
          title: 'Porcelana tipo alfiler',
          text: 'Está formado por una o varias campanas. Se coloca rígidamente en un vástago roscado llamado alfiler con el que forma un conjunto que es desmontable y que se utiliza para soportar un conductor eléctrico.',
          image: 'img1203.jpg'
        },
        {
          title: 'Híbrido tipo poste',
          text: 'Aislador que combina las mejores propiedades de la porcelana y del hule silicón. El núcleo está conformado de porcelana con un envolvente de hule silicón.',
          image: 'img1207.jpg'
        },
        {
          title: 'Sintético tipo poste',
          text: 'Aislador envolvente de hule sintético con núcleo de fibra de vidrio y herrajes metálicos tipo poste.',
          image: 'img1213.jpg'
        },
        {
          title: 'Sintético tipo suspensión',
          text: 'Está formado, al menos, de dos partes aislantes llamadas núcleo y una cubierta de hule silicón.',
          image: 'img1217.jpg'
        }
      ]
    },
    {
      title: 'Cortacircuitos',
      products: [
        {
          title: 'Porcelana apd - cpv',
          text: 'Los balancines del portafusible y el portatubo están fundidos en una aleación de cobre resistente a la corrosión, y su resistencia mecánica soporta la presión de los contactos cuando están en posición de cerrado.',
          image: 'img1230.jpg'
        },
        {
          title: 'Sintético apds',
          text: 'Los balancines del portafusible y el portatubo están fundidos en una aleación de cobre resistente a la corrosión y su resistencia mecánica soporta la presión de los contactos cuando están en posición de cerrado.',
          image: 'img1244.jpg'
        },
        {
          title: 'Portafusible',
          text: 'El portafusible está compuesto de fibra de vidrio, resina epóxica y fundición de aleación de cobre. Estos elementos son resistentes a la corrosión, a los esfuerzos mecánicos y eléctricos.',
          image: 'img1248.jpg'
        }
      ]
    },
    {
      title: 'Apartarrayos',
      products: [
        {
          title: 'Porcelana de óxidos metálicos',
          text: 'Limitador de sobretensiones con varistores de óxidos metálicos de zinc y envolvente de porcelana',
          image: 'img1262.jpg'
        },
        {
          title: 'Sintético de óxidos metálicos',
          text: 'Limitador de sobretensiones con varistores de óxidos metálicos de zinc y envolvente de hule silicón.',
          image: 'img1268.jpg'
        },
        {
          title: 'Sintético de óxidos metálicos tipo riser pol',
          text: 'Limitador de sobretensiones que consta de varistores de óxidos metálicos, con envolvente de hule silicón que le proporciona una gran hidrofobicidad.',
          image: 'img1274.jpg'
        },
        {
          title: 'Sintético iusa aplea',
          text: 'Los Apartarrayos de Línea con Espacio en Aire (APLEA) son equipos que se instalan en forma paralela a los aisladores de línea. Sin carga mecánica que limita las sobretensiones y corrientes de arco causadas por descargas atmosféricas. Esto se lleva a cabo por medio de un electrodo en aire y resistencias no lineales en óxidos metálicos que extinguen el arco que se produce entre el electrodo y el cable de la línea de distribución.',
          image: 'img1280.jpg'
        },
        {
          title: 'Sintético sin accesorios',
          text: 'Limitador de sobretensiones con varistores de óxidos metálicos de zinc y envolvente de hule silicón.',
          image: 'img1284.jpg'
        },
        {
          title: 'Prbt rds',
          text: 'Es un dispositivo de protección contra picos eléctricos, DPS (Dispositivo de Protección Contra Sobretensiones), monopolar compuesto por un varistor de óxido de zinc (MOV), sin descarga en serie.',
          image: 'img1290.jpg'
        },
        {
          title: 'Prbt rds - rc y ra',
          text: 'Es un dispositivo de protección contra picos eléctricos, DPS (Dispositivo de Protección Contra Sobretensiones), monopolar compuesto por un varistor de óxido de zinc (MOV), sin descarga en serie.',
          image: 'img1296.jpg'
        }
      ]
    },
    {
      title: 'Cuchillas',
      products: [
        {
          title: 'Monopolares',
          text: 'Las cuchillas desconectadoras monopolares en aire servicio intemperie de apertura sin carga son de operación manual. Éstas se componen de dos aisladores de porcelana o sintético tipo columna en cada polo. Tienen la apertura en un extremo de la cuchilla.',
          image: 'img1314.jpg'
        },
        {
          title: 'Cuchilla tipo cortacircuito',
          text: 'Cuchilla desconectadora monopolar de operación con pértiga sin carga. Se compone de un aislador sintético de hule silicon; con apertura en un extremo de la cuchilla; se abre y cierra sin dificultad, incluso después de largas exposiciones de los contactos.',
          image: 'img1320.jpg'
        },
        {
          title: 'Cuchilla monopolar puesta en línea',
          text: 'Cuchilla desconectadora monopolar de operación con pértiga sin carga. Se compone de un aislador sintético de hule silicón, con apertura en un extremo de la cuchilla, se abre y cierra sin dificultad instalada sobre la misma línea.',
          image: 'img1326.jpg'
        },
        {
          title: 'Tripolares CoGC y CoG',
          text: 'Las cuchillas desconectadoras tripolares COGC son de operación en grupo con y sin carga COG (con y sin cámara de extinción), de apertura lateral y posición de aisladores en “V”/”H”. El aislamiento puede ser de porcelana o sintético.',
          image: 'img1332.jpg'
        },
        {
          title: 'Tripolares RTP',
          text: 'Las cuchillas desconectadoras tripolares tipo RTP en aire de servicio intemperie, de apertura lateral en un extremo sin carga, son del tipo de operación en grupo. Éstas se integran de tres aisladores de porcelana o sintético tipo columna por polo.',
          image: 'img1344.jpg'
        },
        {
          title: 'Tripolares TTR6',
          text: 'Las cuchillas desconectadoras tripolares tipo TTR6 son de operación en grupo sin carga. Especialmente diseñadas para tensiones superiores a 123 kV.',
          image: 'img1352.jpg'
        },
        {
          title: 'Tripolares DRV',
          text: 'Las cuchillas desconectadoras tripolares DRV son del tipo de apertura lateral central y posición de aisladores en “V”. De operación en grupo sin carga, con o sin puesta a tierra y mecanismo de accionamiento manual o motorizado.',
          image: 'img1358.jpg'
        },
        {
          title: 'Tripolares pantógrafo',
          text: 'Las cuchillas desconectadoras tripolares tipo pantógrafo cuentan con mecanismo de operación por polo y accionamiento controlado.',
          image: 'img1364.jpg'
        },
        {
          title: 'Tripolares TTT7',
          text: 'Las cuchillas desconectadoras tripolares TTT-7 son del tipo de doble apertura lateral central y tres columnas de aisladores. Cuentan con aislador giratorio al centro de cada polo. De operación en grupo sin carga, con o sin puesta a tierra y mecanismo de accionamiento motorizado en la cuchilla principal.',
          image: 'img1370.jpg'
        }
      ]
    },
    {
      title: 'Transformadores',
      products: [
        {
          title: 'Tipo poste',
          text: 'Es un equipo diseñado para obtener su fuente de alimentación en las redes de distribución aérea. Está habilitado para ser instalado en poste o en una estructura similar y puede fabricarse tipo normal o costa, según las necesidades del usuario.',
          image: 'img1382.jpg'
        },
        {
          title: 'Tipo pedestal',
          text: 'Transformador formado en conjunto con un gabinete, en el cual se incluyen accesorios para conectarse a sistemas de distribución subterránea monofásico. Está diseñado para ser montado en un pedestal y servicio intemperie.',
          image: 'img1390.jpg'
        },
        {
          title: 'Tipo sumergible',
          text: 'Transformador diseñado para ser instalado en pozo o bóveda. Ocasionalmente puede sufrir inundaciones, motivo por el cual sus accesorios deben ser herméticos, a prueba de agua y de frente muerto para conectarse en sistemas de distribución subterránea.',
          image: 'img1398.jpg'
        },
        {
          title: 'Tipo subestación',
          text: 'Es un equipo diseñado para obtener su fuente de alimentación en las redes de distribución aérea o subterránea a partir de las características que el proyecto demande. Está habilitado para ser instalado en una plataforma, cimentación o estructura similar. De igual forma, tiene aplicaciones para el acoplamiento directo con tableros por medio de gargantas.',
          image: 'img1655.jpg'
        },
        {
          title: 'Tipo seco',
          text: 'Transformador diseñado para sistemas de distribución de baja tensión, donde en ocasiones se requiere elevar o reducir el nivel de voltaje para alimentar cargas que cuentan con un valor de voltaje diferente a la instalación general. Pueden ser sistemas de iluminación, aires acondicionados, equipos médicos, etc.',
          image: 'img1657.jpg'
        }
      ]
    },
    {
      title: 'Boquillas',
      products: [
        {
          title: 'Baja tensión',
          text: 'Dispositivo de protección usado para conducir una determinada corriente desde el devanado secundario del transformador proporcionando un aislamiento eficaz al exterior.',
          image: 'img1701.jpg'
        },
        {
          title: 'Media tensión',
          text: 'Dispositivo de protección usado en transformadores de distribución en el lado de media tensión para conducir una determinada corriente, desde la línea de media tensión al devanado primario del transformador.',
          image: 'img1712.jpg'
        }
      ]
    },
    {
      title: 'Conductores',
      products: [
        {
          title: 'Alambres y cables de cobre desnudo',
          text: 'Conductores de cobre electrolítico con 99.99% de pureza desnudo. Están construidos en temple duro, semiduro o suave, en forma sólida y cableado concéntrico.',
          image: 'img1728.jpg'
        },
        {
          title: 'Alambres y cables de aluminio desnudo AAC',
          text: 'Los alambres y cables de aluminio desnudo (AAC) están construidos con aluminio 1 350 H-19 temple duro o suave, con 99.5% de pureza, en forma sólida y cableado concéntrico.',
          image: 'img1736.jpg'
        },
        {
          title: 'Conductor de aluminio con cableado concéntrico y núcleo de acero galvanizado ACSR',
          text: 'El conductor está formado por un núcleo con uno o varios alambres de acero galvanizado y cableado en capas concéntricas sobre el núcleo de alambres de aluminio.',
          image: 'img1748.jpg'
        },
        {
          title: 'Conductor de aluminio con cableado concéntrico y núcleo de alambres de acero recubierto de aluminio soldado ACSR/AS',
          text: 'El conductor está formado por un núcleo con uno o varios alambres de acero recubierto de aluminio soldado ACSR/AS y cableado en capas concéntricas sobre el núcleo de los alambres de aluminio.',
          image: 'img1754.jpg'
        },
        {
          title: 'Cables múltiples de distribución aérea a 600 volts',
          text: 'Cable multiconductor formado por un núcleo de cobre o aluminio 1 350 AAC temple duro, forrado con aislamiento individual termoplástico de Polietileno de Alta Densidad (PEAD) color negro.',
          image: 'img1760.jpg'
        },
        {
          title: 'Cables de distribución subterránea XLP DRS a 600 volts',
          text: 'Cable multiconductor formado por un núcleo de cobre o aluminio 1 350 AAC temple duro. De 1, 2 ó 3 conductores de cableado concéntrico, forrado con aislamiento individual termofijo en Polietileno de Cadena Cruzada (XLP) color negro.',
          image: 'img1770.jpg'
        },
        {
          title: 'Cables IUSASIL semiaislados XLP para líneas aéreas de 15, 25 y 38 kV',
          text: 'Conductor de aluminio tipo AAC, o de aluminio con refuerzo de acero tipo ACSR, o de cobre.',
          image: 'img1784.jpg'
        },
        {
          title: 'Cables IUSASIL de energía XLP de 5, 15, 25 y 35 kV',
          text: 'Cable monoconductor formado por un núcleo de cobre suave o aluminio 1 350 temple duro con material sellador, pantalla semiconductora sobre el conductor y aislamiento de Polietileno de Cadena Cruzada (XLP) con o sin retardante a las arborescencias (XLP, XLP-RA).',
          image: 'img1796.jpg'
        },
        {
          title: 'Cable solar',
          text: 'Cable multiconductor PVC+NYLON+PVC tipo CT. Este cable está disponible en calibres del 16 AWG al 8 AWG con construcción de 2 a 4 conductores aislados. El cable utiliza conductores tipo THHN o THWN-2 en calibres del 14 AWG al 8 AWG y conductores tipo TFFN en calibre 16 AWG.',
          image: 'img1814.jpg'
        },
        {
          title: 'Alambres y cables THHN/THWN-2 90°C 600 volts',
          text: 'Alambre o cable de cobre suave con aislamiento termoplástico de Policloruro de Vinilo (PVC) y cubierta protectora de nylon.',
          image: 'img1820.jpg'
        },
        {
          title: 'Alambres y cables THW-LS/THHW-LS 75°C/90°C 600 volts',
          text: 'Conductor de cobre suave formado por uno o varios alambres cableados con aislamiento termoplástico de Policloruro de Vinilo (PVC) de colores.',
          image: 'img1838.jpg'
        },
        {
          title: 'Cable alambrado de tableros',
          text: 'Cable de cobre suave; clases B, C y flexible K; con aislamiento termoplástico de Policloruro de Vinilo (PVC-LS).',
          image: 'img1846.jpg'
        },
        {
          title: 'Cable tipo USE-2/RHH/RHW-2',
          text: 'Conductor de cobre o aluminio con cableado concéntrico comprimido, con cinta separadora y aislamiento de Polietileno de Cadena Cruzada (XLP) en color negro.',
          image: 'img1959.jpg'
        },
        {
          title: 'Cable tipo XHHW-2',
          text: 'Conductor de cobre o aluminio con cinta mylar separadora y aislamiento de Polietileno de Cadena Cruzada (XLP) en color negro.',
          image: 'img1959.jpg'
        },
        {
          title: 'Cables control PVC/PVC para 600 volts',
          text: 'Conductor de cobre suave con cableado concéntrico clase B, con aislamiento termoplástico de Policloruro de Vinilo (PVC) en código de colores tipo THHW, con cinta mylar separadora y cubierta termoplástica de Policloruro de Vinilo (PVC) color negro.',
          image: 'img1856.jpg'
        },
        {
          title: 'Tubería de cobre rígida SPS',
          text: 'Esta tubería se utiliza como terminal eléctrica y/o conductor eléctrico debido a la alta conductividad del cobre con el cual está fabricado el tubo SPS tipo regular (ETP y DLP).',
          image: 'img1864.jpg'
        }
      ]
    },
  ]
}
